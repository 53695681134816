<script setup>

</script>

<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 494 81" style="enable-background:new 0 0 494 81;" xml:space="preserve">
  <polygon points="149,29.1 152.3,30.4 152.4,30.2 149.1,29 "/>
      <rect x="440.3" y="64.7" width="3.5" height="0.1"/>
      <rect x="449.3" y="28.6" transform="matrix(0.465 -0.8853 0.8853 0.465 215.9688 414.6745)" width="3.5" height="0.1"/>
      <polygon points="206.1,58.8 203.5,61.2 203.6,61.3 206.2,58.9 "/>
      <polygon points="204.4,35.2 204.4,38.7 204.5,38.7 204.5,35.2 "/>
      <g>
    <g>
      <rect x="244.9" y="64.7" width="3.5" height="0.1"/>
      <path d="M249.7,50.8c3.1-0.5,6.2-1.7,8.5-3.7c2.4-2.1,3.8-6.3,3.8-9.5c-0.1-3-1.3-5.8-4.2-7.1c-2.6-1.2-5.6-1.2-8.4-1.2
        c-2.5,0-5,0.2-7.6,0.3c-1.3,0-2.3,0.1-3.6,0.1c-0.9,0-1.9,0.1-2.6,0c-0.3,0-1.2-0.3-1.3-0.3c0,0.1,0.5,0.3,0.7,0.4
        s0.4,0.2,0.4,0.3c0.5,0.3,0.8,0.6,1.2,1.1c1,1.4,1.3,3,1.3,4.7c0.1,3.8,0.1,7.7,0.1,11.6s-0.1,7.8-0.3,11.7c0,0.9-0.1,1.9-0.2,2.8
        c0,0.6-0.1,1.3,0,1.9c0.3,1.4,1.7,1.1,2.9,1.1c2.3,0,4.5,0,6.7,0c-0.2,0-0.2-0.1-0.4-0.1c-0.1,0-0.6,0-1.1-0.3
        c-0.8-0.5-1.3-1.4-1.4-2.1c-0.3-1.3-0.1-2.7,0-4.1c0-2.5,0-4.9,0-7.4c0.1-5.8,0.1-11.5,0.1-17.3c0-0.5-0.2-1.3,0.3-1.6
        c0.4-0.3,0.9-0.3,1.4-0.4c1.4-0.2,2.8-0.3,4.1-0.2c2.8,0.3,4.8,2.9,5.5,5.4c0.7,2.5,0.4,6.4-1.3,8.5c-1.5,1.8-3,2.7-5.3,3
        c-0.5,0.1-0.7,0.1-1.2,0.3c-0.3,0.1-0.5,0.4-0.7,0.6c-0.6,0.6-1.3,1.5-1.8,2.1C247.1,51.2,248.2,51.1,249.7,50.8"/>
      <polygon points="233.6,29.1 236.8,30.5 236.9,30.3 233.7,29 		"/>
    </g>
        <g>
      <polygon points="233.2,54.6 232.6,58 232.8,58 233.4,54.6 		"/>
          <path d="M233.1,55.9l-0.1-0.2c0,0.1-0.1,0.3-0.1,0.4c-0.7,2.6-3,4.5-5.5,5.6c-2,0.9-4.1,1-6.2,0.9c-2.1-0.1-4.1-0.3-6.2-0.5
        c-0.6-0.3,0-1,0.2-1.4c0.4-0.7,0.8-1.3,1.2-2c0.6-1,1.2-2,1.8-3c1.1-2,2.3-4,3.4-5.9c2.5-4.3,5-8.5,7.4-12.8
        c0.9-1.5,1.8-3,2.6-4.6c0.3-0.6,1-1.4,0.9-2.1c-0.1-0.6-0.6-0.7-1.1-0.7c-1,0-2,0-3,0c-2,0-4,0-6,0c-4,0-8.2,0-12.2,0
        c-0.8,0-1.6-0.1-2.1,0.6c-0.4,0.6-0.3,1.3-0.3,1.9c0,1,0,1.6,0,2.5c0,0.5,0,1.1,0,1.7c0,0.2,0,1.3,0,1.4c0,0,0.1-0.1,0.2-0.4
        c0.1-0.5,0.2-0.8,0.3-1c0.4-0.6,0.7-0.9,0.7-1c0.5-0.6,0.8-1,1.9-2c2.2-1.9,5.1-2.1,7.9-2.1c1.2,0,2.4,0,3.7,0.1
        c0.4,0.1,1,0.1,1.3,0.4c0.5,0.4,0,0.9-0.2,1.3c-1.7,2.9-3.4,5.8-5.1,8.7c-3.9,6.6-7.7,13.3-11.6,19.9c-0.4,0.7-1,1.6-1.2,2.2
        c0,0.1-0.1,0.9,0.1,0.9c0.4,0,0.9,0,1.3,0c1.8,0,3.5,0,5.3,0c3.9,0,7.7,0,11.6,0c1.8,0,3.3,0,5.1,0c0.5,0,1.1,0.1,1.6-0.1
        s0.8-0.9,0.9-1.3c0.4-1.2,0.6-2.5,0.8-3.8C232.7,58.4,233,57.1,233.1,55.9"/>
          <polygon points="207.9,35.3 207.9,38.7 208,38.7 208,35.4 		"/>
    </g>
        <path d="M287.9,30.6c-5-2.3-11.3-2-16.3,0.4c-5.4,2.7-8.5,8.1-9.1,14c-0.6,6,1.2,12.8,5.7,17.1c4.7,4.5,12.4,5.4,18.3,3
      c12-4.7,14.4-21.9,6.1-31C291.3,32.6,289.7,31.4,287.9,30.6 M281.6,64.1c-1.3,0.2-2.8,0-4-0.5c-1-0.4-2-0.9-2.8-1.6
      c-1.7-1.5-2.9-3.6-3.8-5.7c-1.8-4.6-2.4-9.9-1.8-14.7c0.4-3,1.4-5.9,3.6-8c2-1.9,4.9-2.8,7.7-2.4c4.8,0.7,7.4,5.4,8.7,9.7
      c1.3,4.3,1.8,8.9,1,13.3c-0.7,4.3-2.9,8.9-7.6,9.8C282.3,64,282,64.1,281.6,64.1"/>
        <path d="M377.4,30.6c-5-2.3-11.3-2-16.3,0.4c-5.4,2.7-8.5,8.1-9.1,14c-0.6,6,1.2,12.8,5.7,17.1c4.7,4.5,12.4,5.4,18.3,3
      c12-4.7,14.4-21.9,6.1-31C380.8,32.6,379.2,31.4,377.4,30.6 M371.1,64.1c-1.3,0.2-2.8,0-4-0.5c-1-0.4-2-0.9-2.8-1.6
      c-1.7-1.5-2.9-3.6-3.8-5.7c-1.8-4.6-2.4-9.9-1.8-14.7c0.4-3,1.4-5.9,3.6-8c2-1.9,4.9-2.8,7.7-2.4c4.8,0.7,7.4,5.4,8.7,9.7
      c1.3,4.3,1.8,8.9,1,13.3c-0.7,4.3-2.9,8.9-7.6,9.8C371.8,64,371.5,64.1,371.1,64.1"/>
        <g>
      <polygon points="385.3,29.1 388.6,30.2 388.7,30.1 385.3,29 		"/>
          <polygon points="411.6,54.6 411.1,57.8 411.2,57.9 411.8,54.7 		"/>
          <path d="M411.6,55.7c-0.1,0.2-0.1,0.2-0.2,0.5c-1.3,3.1-4,5.8-7.5,6.3c-1.3,0.2-2.7,0.2-4,0.1c-1-0.1-2.3-0.3-2.8-1.3
        c-0.4-0.8-0.3-1.8-0.3-2.7c0-1.1,0-2.2,0-3.3c0-3.9,0.1-7.8,0.1-11.7c0-3.8,0-7.5,0.2-11.3c0-0.7,0.1-1.7-0.2-2.3
        c-0.2-0.4-0.8-0.4-1.2-0.4c-1.2,0-2.3,0.1-3.5,0.1c-1.1,0-2.4,0.1-3.5,0c-0.6,0-0.7,0-1.4-0.1c-0.1,0-0.7-0.1-0.8-0.2
        c0.8,0.4,1.5,0.8,2.2,1.4c1,0.8,1.5,2.2,1.6,3.4c0.2,1.6,0,3.2,0.1,4.8c0,1.6,0,3.3,0,4.9c0,6.6,0,13.2-0.5,19.7
        c0,0.4,0.2,0.8,0.5,1c0.4,0.3,0.9,0.2,1.3,0.2c1,0,2,0,3,0c2.1,0,4.2,0,6.4,0c2.5,0,5,0,7.5,0c0.9,0,1.2-0.7,1.4-1.4
        C410.4,62.3,411.5,57.2,411.6,55.7z"/>
    </g>
        <g>
      <rect x="452.7" y="69.1" width="2.9" height="0.2"/>
          <g>
        <path d="M484.7,69.1c-1.6-0.4-3.3-1.9-4.9-5.7c-2.3-5.6-12.9-31.8-14.5-35.9c-0.2-0.4-0.6-0.4-0.6-0.4l-2.7-0.8
          c0,0-0.3,0.8-0.9,2.2l-0.6,1.3c-2.9,6.3-14.9,32.9-14.9,32.9c-1.2,2.8-1.9,5.9,0.8,6.6c0.2,0.1,0.5,0.1,1.1,0.1s0.8,0,0.8,0h7.2
          c-0.2,0-0.2-0.1-0.4-0.1c-0.1,0-0.5,0-0.7-0.1c-1.7-0.3-3.9-1.9-4.1-3.6c-0.1-0.6,0.2-1.7,0.4-2.1c1.2-3.5,1.2-3.5,11.9-29.2
          l3.9,11.9c0.6,1.3-1.8,1.2-2.5,1.2h-2.5c-0.4,0-0.7,0-1,0.1c-0.7,0.6-4.2,3.2-4.2,3.2h6.2c3.1,0,6,0,6,0l6.2,17
          c0.4,0.9,0.8,1.6,3,1.6h8.2C485.7,69.3,485.6,69.2,484.7,69.1z"/>
      </g>
          <rect x="483.4" y="69.1" width="3.5" height="0.2"/>
          <polygon points="463.7,26.7 460.3,25.6 460.3,25.8 463.6,26.9 		"/>
    </g>
        <g>
      <g>
        <path d="M124.6,69.1c-2.1-0.2-2.7-1.1-3.3-1.9c-1-1.2-1.8-3.3-2.5-5.5c-0.6-2-1.2-4.4-2.1-7.3c-1.9-7.3-9.3-9-9.2-9
          c4.7-1,9.1-3.1,10.2-7.6c0.4-1.7,0.6-3.9-0.3-6.3c-1.8-4.6-8.5-5.2-13-5.1c-0.2,0-0.5,0-0.7,0h-0.8c-1.4,0-2.5,0.1-3.1,0.1
          c-6.6,0.5-10.6,0.4-12.7,0.2c-1.4-0.1-2.1-0.4-2.1-0.4s4.5,1.5,4.5,4.8c0,0.7,0,12.5,0.1,16.3c0,3.8-0.3,16.3-0.6,19.2
          c-0.3,2.7,2.8,2.6,2.8,2.6h9.3c-0.4,0-0.1-0.1-0.5-0.2c-0.9-0.1-1.3-0.3-1.5-0.4c-0.9-0.4-1.7-1-2.3-1.8
          c-0.8-1.3-0.7-2.6-0.8-4.1c0-0.4,0-0.6,0-0.6l0.1-11.9V50v-3v-0.2l0.1-17c0-0.3,0.1-0.4,0.3-0.6c1.9-0.6,4-1.1,6.1-1l0.9,0.1
          c0.3,0,0.5,0.1,0.8,0.1c4.1,0.7,7.3,4.4,6.5,9.4c-0.7,4.7-3.8,5.7-6.6,6.2c-0.2,0-0.5,0.1-0.7,0.1l-0.7,0.1
          c-0.6,0.1-2.2,0.2-2.8,0.2h-1.3c0,0-0.3-0.1-0.5,0.3c-0.3,0.5-1.1,1.8-1.6,2.8l0,0c1.9,0,4.1,0,5.5,0h0.6c0.3,0,0.6,0.1,0.9,0.1
          c4.9,0.8,5.1,4.6,5.4,5.2l3.4,13c0.9,3.4,3.3,3.3,3.6,3.3c0,0,6.1,0,8,0C124.2,69.3,125,69.3,124.6,69.1z"/>
      </g>
          <rect x="122.3" y="69.1" width="3.5" height="0.2"/>
          <rect x="98.9" y="69.1" width="2.8" height="0.2"/>
          <polygon points="88,27.3 84.7,26.2 84.6,26.4 87.9,27.5 		"/>
    </g>
        <g>
      <path d="M160.2,49.3c-0.1,0.8,0,1.6,0,2.4c0,0.9,0,1.7-0.1,2.6c0,1.6,0,3.2,0.1,4.9c0,0.6,0.1,1.3,0.2,1.9
        c0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.3,0.7,0.2,1,0.2c1.7,0.1,3.4,0.3,5,0.4c1.7,0.1,3.3,0.2,5,0c1.5-0.2,2.9-1.1,4-2.1
        c0.9-0.8,1.6-1.8,2.3-2.8c0.3-0.4,0.5-0.7,0.6-1.1c0.2-0.4,0.1-0.4,0.2-0.5c0.1-0.1,0.1,0.1,0.2-0.1c-0.4,1.8-0.7,3.7-1.1,5.4
        c-0.1,0.6-0.3,1.3-0.4,1.9c-0.1,0.5-0.3,1.1-0.7,1.4s-1.4,0.2-1.9,0.2c-1,0-2.1,0-3.1,0c-2,0-4,0-5.9,0c-3.5,0-7,0-10.5,0
        c-0.6,0-1.2,0-1.5-0.6c-0.3-0.5-0.4-1.1-0.3-1.7c0.1-1.9,0.2-3.8,0.3-5.7c0.2-5.5,0.2-11.1,0.2-16.6c0-1.4,0-2.8-0.1-4.1
        c0-1.2,0-2.5-0.4-3.6c-0.3-0.9-0.7-1.4-1.6-2.1c-0.4-0.4-1.6-0.7-1.5-0.8c0-0.1-0.9-0.3-0.1-0.1c0.6,0.1,1.7,0.1,1.8,0.1
        c0.6,0,1.2-0.1,1.8-0.1c1.3,0,2.6,0,3.9,0c2.9,0,5.8,0,8.7,0c1.5,0,3,0,4.6,0c0.7,0,1.4,0,2.1,0c0.6,0,1.1,0.1,1.4,0.6
        c0.2,0.3,0.3,0.8,0.4,1.2c0.1,0.7,0.3,1.3,0.4,2c0.3,1.5,0.6,3,0.9,4.5c0-0.1-0.1,0.2-0.1,0.1c0-0.2-0.2-0.4-0.3-0.7
        c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.6-1.8-2.5-2.7c-1-0.9-2.2-1.7-3.6-2c-1.6-0.4-3.3-0.4-5-0.3c-0.8,0-1.6,0.1-2.5,0.1
        c-0.8,0-1.3,0.3-1.4,1.1c-0.1,1.5,0,3,0,4.4c0,3.1,0,6.2,0,9.3C160.2,47.3,160.2,48.3,160.2,49.3"/>
          <path d="M160.8,48c0.4-0.9,0.8-1.8,1.2-2.7c0.1-0.2,0.2-0.8,0.5-0.9c0.3-0.2,0.8-0.1,1.2-0.1c2.5,0,5,0,7.4,0
        c-0.3,2.2-2.8,3.3-4.7,3.6C164.5,48.2,162.6,48,160.8,48"/>
          <rect x="176.9" y="56.4" transform="matrix(0.187 -0.9824 0.9824 0.187 89.8299 221.3463)" width="3.5" height="0.1"/>
          <polygon points="175.7,35.7 176.4,39.2 176.5,39.1 175.8,35.7 		"/>
    </g>
        <path d="M178.9,48.9c0.3,3,1.2,6,2.9,8.6c1.6,2.6,3.9,5.2,6.8,6.5c2.4,1.1,5.5,1.9,8.2,1.3c1.1-0.2,2.3-0.9,3.3-1.4
      c1.2-0.7,2.1-1.4,3.1-2.2c1.1-0.9,1.6-1.7,2.6-2.5c-0.2,0.2-0.5,0.5-0.8,0.6c-0.1,0-0.2,0.1-0.2,0.1c-0.6,0.3-1.2,0.5-1.8,0.7
      c-1.4,0.5-2.8,0.6-4.2,0.6c-3.1,0.1-6.1-0.8-8.6-2.6s-3.7-4.6-4.4-7.6c-0.6-2.9-0.8-6-0.4-8.9c0.3-2.4,1-4.8,2.4-6.8
      c1.3-1.8,3.2-3,5.5-3.5c2.2-0.5,4.5-0.3,6.4,0.7c1.1,0.6,1.5,0.8,3,2.1c0.4,0.4,1.5,1.5,1.6,2.1c0,0.1,0.2,0.7,0.1,0.9
      c-0.1,0.2,0.1-0.1,0.1,0c0-1.8,0-3.2,0-4.9c0-0.3,0-0.7,0-1c0-0.2,0-0.9-0.6-1.2c-0.4-0.2-0.9-0.4-1.3-0.6
      c-3.3-1.2-6.9-1.4-10.3-0.6c-5.2,1.1-9.5,4.5-11.7,9.3c-0.6,1.4-1.1,2.8-1.3,4.3C178.9,44.9,178.9,46.9,178.9,48.9"/>
        <g>
      <polygon points="149.5,54.6 148.9,58 149.1,58 149.7,54.6 		"/>
          <path d="M149.4,55.9l-0.1-0.2c0,0.1-0.1,0.3-0.1,0.4c-0.7,2.6-3,4.5-5.5,5.6c-2,0.9-4.1,1-6.2,0.9c-2.1-0.1-4.1-0.3-6.2-0.5
        c-0.6-0.3,0-1,0.2-1.4c0.4-0.7,0.8-1.3,1.2-2c0.6-1,1.2-2,1.8-3c1.1-2,2.3-4,3.4-5.9c2.5-4.3,5-8.5,7.4-12.8
        c0.9-1.5,1.8-3,2.6-4.6c0.3-0.6,1-1.4,0.9-2.1c-0.1-0.6-0.6-0.7-1.1-0.7c-1,0-2,0-3,0c-2,0-4,0-6,0c-4,0-8.2,0-12.2,0
        c-0.8,0-1.6-0.1-2.1,0.6c-0.4,0.6-0.3,1.3-0.3,1.9c0,1,0,1.6,0,2.5c0,0.5,0,1.1,0,1.7c0,0.2,0,1.3,0,1.4c0,0,0.1-0.1,0.2-0.4
        c0.1-0.5,0.2-0.8,0.3-1c0.4-0.6,0.7-0.9,0.7-1c0.5-0.6,0.8-1,1.9-2c2.2-1.9,5.1-2.1,7.9-2.1c1.2,0,2.4,0,3.7,0.1
        c0.4,0.1,1,0.1,1.3,0.4c0.5,0.4,0,0.9-0.2,1.3c-1.7,2.9-3.4,5.8-5.1,8.7c-3.9,6.6-7.7,13.3-11.6,19.9c-0.4,0.7-1,1.6-1.2,2.2
        c0,0.1-0.1,0.9,0.1,0.9c0.4,0,0.9,0,1.3,0c1.8,0,3.5,0,5.3,0c3.9,0,7.7,0,11.6,0c1.8,0,3.3,0,5.1,0c0.5,0,1.1,0.1,1.6-0.1
        s0.8-0.9,0.9-1.3c0.4-1.2,0.6-2.5,0.8-3.8C149,58.4,149.3,57.1,149.4,55.9"/>
          <polygon points="124.2,35.3 124.2,38.7 124.3,38.7 124.3,35.4 		"/>
    </g>
        <g>
      <polygon points="319.4,37 318.4,40.4 318.5,40.4 319.5,37.1 		"/>
          <path d="M324.4,53.6c0,3-0.8,5.9-3,8.1c-1.8,1.8-4.3,2.8-6.7,3.4c-5.1,1.2-10.6,0.6-15.1-2c-0.6-0.3-1.2-0.8-1-1.5
        s0.4-1.4,0.7-2.1c0.5-1.5,1.1-3.1,1.5-4.6c0.1-0.3,0.2-0.5,0.3-0.7c0,0.2-0.1,0.6-0.1,0.8c0.2,2.3,1.4,4.4,3.1,6.1
        c2.1,2.2,5.3,2.8,8.2,2c2.9-0.8,4.8-3,5.2-5.9c0.4-2.7,0.3-6.6-2.6-8c-1.4-0.7-2.9-0.8-4.4-1c-1.6-0.2-3.3-0.4-4.8-0.8
        c-3.1-0.8-5.5-2.8-6.2-5.9c-0.7-2.7-0.2-5.7,1.5-8c1.9-2.5,5-3.7,8.1-4c3.3-0.3,6.7,0.2,9.7,1.6c0.7,0.3,1.4,0.7,2.1,1.1
        c0.6,0.4,0.1,1.3-0.1,1.9c-0.4,1.4-1.3,4.2-1.8,5.6c-0.2,0.5-0.1-0.3-0.2,0.1c0.1-0.4,0.2-0.9,0.2-1.2c-0.1-1.1-0.5-1.8-0.8-2.6
        c-0.5-1.2-1.3-2.4-2.4-3.2c-2.3-1.7-5.9-2.3-8.4-0.5c-2.1,1.5-3.1,4.8-2.1,7.2c1.1,2.7,4,3.7,6.7,4.2c3.2,0.5,6.9,0.5,9.6,2.7
        C323.8,48,324.4,50.7,324.4,53.6"/>
          <polygon points="301.2,52.9 300.2,56.2 300.3,56.2 301.4,52.9 		"/>
    </g>
        <g>
      <rect x="334.4" y="64.7" width="3.5" height="0.1"/>
          <path d="M339.3,50.8c3.1-0.5,6.2-1.7,8.5-3.7c2.4-2.1,3.8-6.3,3.8-9.5c-0.1-3-1.3-5.8-4.2-7.1c-2.6-1.2-5.6-1.2-8.4-1.2
        c-2.5,0-5,0.2-7.6,0.3c-1.3,0-2.4,0.1-3.6,0.1c-0.9,0-1.9,0.1-2.6,0c-0.3,0-1.3-0.3-1.3-0.3c0,0.1,0.5,0.3,0.7,0.4
        s0.4,0.2,0.4,0.3c0.5,0.3,0.8,0.6,1.2,1.1c1,1.4,1.3,3,1.3,4.7c0.1,3.8,0.1,7.7,0.1,11.6s-0.1,7.8-0.3,11.7c0,0.9-0.1,1.9-0.2,2.8
        c0,0.6-0.1,1.3,0,1.9c0.3,1.4,1.7,1.1,2.9,1.1c2.3,0,4.5,0,6.7,0c-0.2,0-0.2-0.1-0.4-0.1c-0.1,0-0.6,0-1.1-0.3
        c-0.8-0.5-1.3-1.4-1.4-2.1c-0.3-1.3-0.1-2.7,0-4.1c0-2.5,0-4.9,0-7.4c0.1-5.8,0.1-11.5,0.1-17.3c0-0.5-0.2-1.3,0.3-1.6
        c0.4-0.3,0.9-0.3,1.4-0.4c1.4-0.2,2.8-0.3,4.1-0.2c2.8,0.3,4.8,2.9,5.5,5.4s0.4,6.4-1.3,8.5c-1.5,1.8-3,2.7-5.3,3
        c-0.5,0.1-0.7,0.1-1.2,0.3c-0.3,0.1-0.5,0.4-0.7,0.6c-0.6,0.6-1.3,1.5-1.8,2.1C336.7,51.2,337.7,51.1,339.3,50.8"/>
          <polygon points="323.2,29.1 326.4,30.5 326.5,30.3 323.2,29 		"/>
    </g>
        <g>
      <path d="M410.2,29.3l-0.1-0.1c1.3,0.6,2.4,1.3,3.1,2.4c1,1.5,0.8,3.4,0.9,5.1c0.1,3.9,0.1,7.8,0.1,11.7s-0.1,7.8-0.3,11.7
        c-0.1,1.2-0.5,3,0.3,4.1c0.4,0.5,0.9,0.5,1.5,0.5c0.9,0,1.8,0,2.6,0c1.9,0,3.8,0,5.7,0c-0.1,0-0.1-0.1-0.1-0.1
        c-3.3-0.4-3.4-3.7-3.3-6.3c0.1-3.6,0.1-7.3,0.1-10.9c0-3.9,0.1-7.9,0.2-11.8c0-0.9,0-1.8,0.1-2.7c0.1-0.8,0.2-1.9-0.1-2.7
        c-0.3-0.7-0.8-0.7-1.5-0.7s-1.4,0-2.1,0c-2.1,0-4.2,0.1-6.2-0.1c-0.1,0-0.6-0.1-0.9-0.3"/>
          <rect x="421.5" y="64.7" width="3.5" height="0.1"/>
          <polygon points="409.6,29.1 412.8,30.6 412.8,30.5 409.7,28.9 		"/>
    </g>
        <path d="M433.1,35.2c-0.6,0.2-0.5,0.7-0.5,1.2c0,0.9,0,1.8,0,2.7c0,2,0,3.4,0,5.4c0,3.9-0.1,7.8-0.2,11.7c-0.1,1.8-0.1,3.5-0.2,5.3
      c-0.1,0.9-0.2,2.1,0.3,2.8c0.3,0.4,0.7,0.5,1.2,0.5c0.9,0,2,0,2.9,0c1.5,0,3,0,4.5,0c0.7,0,1.4,0,2.2,0c-0.4,0,0.1-0.1-0.6-0.1
      c-0.2,0-0.4,0-0.5-0.1c-1-0.2-1.8-1.3-2.3-2.1c-0.8-1.3-0.9-2.8-1-4.3c-0.1-3.5,0-7.1,0.1-10.6c0.1-4.9,0.1-9.3,0.3-14.2
      C437.2,34,435,34.6,433.1,35.2"/>
  </g>
      <rect x="424.8" y="34.6" width="0.1" height="3.1"/>
      <path d="M424.9,29.9c0.1-0.4,0.6-0.4,1.1-0.4c0.7,0,0.8,0,1.5,0c3,0,6.3,0,9.3,0s6.9,0,9.9,0c1,0,2.1-0.2,3.1-0.5
    c0.4-0.2,0.7-0.2,1.1-0.4c0.2-0.1,0.5-0.3,0.6-0.5c0-0.1-0.1,0.2,0,0.2c-0.5,0.9-1,1.8-1.4,2.7c-0.3,0.5-0.6,1.7-1.3,1.8
    c-0.9,0.1-1.6,0.1-2.5,0.1c-1.5,0-4-0.1-5.8-0.1c-1.6,0-3.4-0.1-5-0.1c-2,0-4.1-0.3-6.1,0.1c-1.8,0.4-3.3,0.8-3.9,2.4
    c-0.2,0.5-0.3,0.7-0.4,1.6l-0.1-0.3C424.8,35.5,424.7,31,424.9,29.9z"/>
      <g>
    <path d="M7.2,9.6c0.4,0.9,1,1.9,2,2.4c0.2-0.4,0.7-0.6,1.2-0.7c0.5,1.2,0.6,1.6,1.6,2.6c3.3,1,7.6,1.9,10.6,3.4
      c0-1.6,1-2.5,1.9-2.9c-0.7,0.5-1.2,1.1-0.9,2.1c0.7,2.4,4.6,3.7,7.2,4.8c3.8,1.6,7.3,3.6,10.6,6.4c3.7,2.2,5.3,4.3,5,7.3
      c-0.1,0.6-0.2,0.9-0.6,1.3c-0.6,0.7-1.5,0.3-1.5,1.2c2.4,0.2,4.6,0.6,6.5,2c1.8-0.3,3-1.7,4.6-2.4c1.6-1.5,2.4-3.6,2-5.8
      c-0.1-0.7-0.4-1.6-1.2-1.9c-2.3,0.1-5.1,0.7-6.7-1.2c-0.1-0.2-0.1-0.4-0.1-0.4c0.6,0.9,2,0.8,2.9,0.5l0.4-0.2
      c-1.7-0.3-2.6-2.5-4.5-2.3c-0.2,0.2-0.3,0.8-0.2,1c-0.7-0.5-1-1.4-0.8-2.2c0.2-0.7,0.9-1.6,2.8-1.6c0.7,0,1.2,0.1,1.2,0.1
      c0-0.2-0.2-0.5,0.2-1.1c0.4-0.5,1.3-0.7,2.2-0.7c-0.2-0.2-0.3-0.4-0.1-0.6s0.8,0,0.5-0.6c-0.4-0.9-1.2-1.8-0.4-2.7
      c0.9-1.1,2.4-0.4,3.6-0.7c0.1,0.2,0.1,0.6,0.3,0.8c0.4,0,1-0.2,1.2-0.6c-0.2-0.1-0.3-0.1-0.6-0.2c0-0.2,0.1-0.5,0.1-0.8
      c0,0,0.4,0.1,0.7,0.1c0.1-0.2,0.1-0.5,0.2-0.7c0.4,0.1,0.6,0.1,0.9,0.2c0,0,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.4l0.6,0.2l-0.2,0.7
      c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0.3,0.2,0.7,0.5,1c0.3-0.1,0.6-0.5,0.9-0.7c0.8,0.8,3.3,0.9,2.5,2.7c-0.3,0.8-1.4,1.1-1.8,1.8
      c0,0.2,0.2,0.4,0.2,0.6c-0.1,0.2-0.3,0.4-0.5,0.2c-0.4,0.2,0.1,0.5,0,0.7c0.3,0.2,0.5,0.5,0.8,0.7c0.6,0,1.2-0.1,1.6-0.4
      c0.4-0.3,0.1-0.9,0-1.2c0.5,0.5,0.9,0.9,0.7,1.8c-0.1,0.6-0.9,1-1.4,1.1c-0.1,0.4,0.1,0.6,0.4,1c0.1,0,0.2,0,0.3-0.2
      c0,0,0,0.3,0,0.4s0,0.4,0,0.4c2,3.4,3,7.3,3.5,11.3c0.2,0.4,0.3,0.6,0.7,0.8c1.3-0.1,2.1-1.5,3.4-1.8c0-0.8-1.1-0.3-1.7-0.9
      c-0.9-0.9-0.9-2.3-0.7-3.5c0.5-2.5,3.1-4.1,5.5-5.3c1.8-1.4,2.6-2.5,4.9-3.6c3.4-1.6,6.9-2.6,9.8-4.8c0.4-0.3,0.8-0.8,1.1-1.2
      c0.2-0.4,0.3-0.8,0.1-1.3c-0.2-0.5-0.9-0.9-0.9-0.9c1.2,0.5,1.6,1.4,1.6,2.4c3-0.9,6.2-1.5,9.2-2.6c1.2-0.4,2.1-1.6,2.3-2.6
      c0.5,0,0.9,0.4,1.2,0.7c0.8-0.7,1.4-1.4,1.6-2.1c0.1-0.3,0.2-0.7,0-0.9s-0.3-0.2-0.3-0.2c0.4-0.2,1,0.2,1.3,0.7
      c0.5,0.7,0.5,1.8,0.3,2.6c0,0.3,0.2,0.2,0.4,0.7c0.5,1.8,0.1,2.7-1.5,3.9c-0.9,0.7-1.3,0.3-0.8,1c0.3,0.4,0.4,1,0.3,1.5
      c-0.3,1.3-1.8,1.6-1.9,2.8c0.4,0.7,0.2,1.8-0.3,2.4c-0.7,0.9-1.5,1.5-2.4,2.2c0.3,0.8,0.2,1.7-0.4,2.4c-0.9,1.3-2.4,2-3.9,2.6
      c0.2,0.9,0.1,1.7-1.1,2.3c0.2,0.5,0.3,1.1-0.1,1.6c-0.4,0.6-1.1,1-1.7,1.2c0.3,0.2,0.6,0.5,0.5,1c-0.1,0.8-0.4,1.2-1.6,1.4
      c-0.9,0.2-2.1,0-2.4,0.6c0.7,0.2,1.6-0.1,1.9,0.8c0,0.5-0.3,1-0.7,1.3c-1,0.7-2.7,0.3-3.8,0c-0.2,0.1-0.4,0.3-0.4,0.4
      c1.1,0.5,3.2,0.8,2.5,2.3c-0.1,0.1-0.5,0.9-2.5,0.3c-0.5-0.2-1-0.5-1.5-0.7L85,46c0.6,0.5,1.2,1,1.4,1.8c0.1,0.5-0.2,1-0.7,1.4
      c-0.6,0.3-1.9,0.2-2.6-0.1c-0.1-0.1-0.2,0-0.4,0.3c0.2,0.4,0.6,0.7,0.2,1.2c-0.2,0.3-0.7,0.6-1.1,0.6c-0.9,0.2-1.7-0.2-2.4-1
      c-0.2-0.1-0.1,0-0.3,0c0.1,0.6,1,1.1,0.6,2c-0.1,0.5-0.8,0.9-1.5,1c-0.7,0-1.2-0.2-1.6-0.2c-0.1,0.1-0.6,0.7-1.4,0.8
      s-1.7-0.2-2.1-0.6c-0.8,0.7-2.5,1.2-3.8,0.3c-0.1,0.5,0,0.9-0.8,1.3C68,55,67.5,55.1,67,55c-0.7-0.2-0.9-0.8-1.2-0.3
      c-0.3,0.5-1.5,0.8-2.2,0.5c-0.2-0.1-0.5-0.2-0.5-0.3c-0.1,0-0.6,0.5-1.4,1c-0.3,0.2-0.6,0.2,0,0.4c1,0.4,1.4,1.6,2.6,2.4
      c0.2,0.4,0.6,0.7,0.8,0.9c1.2,0.4,3.2,1.6,4.6,1.7c-0.1-1.8-1.1-4.4,0.7-5.8c1-0.6,2-1.2,3.1-0.8c0.4,0.1,0.8,0.3,1.1,0.7
      c-0.4-0.3-0.8-0.4-1.4-0.4c-0.6,0.1-1,0.1-1.4,0.5c0.3,0.3,0.3,0.8,0.1,1.2c-0.2,0.4-0.7,0.8-1.2,1c0,0.2,0.4,0.3,0.5,0.6
      c0.5,1.1-1.6,1.2-0.3,2.1c0.9-1.6,2.2-3.7,4.2-3.8c1.1,0,2.3,0.1,2.8,1.4c0.2,0.6,0.1,0.7,0.1,0.7c0-0.3-0.4-0.9-0.9-1.2
      c-0.4-0.2-1.1-0.2-1.5,0.1c0.1,0.4-0.1,0.7-0.6,0.8s-1.7,0-1.7,0c0.1,0.7,0,1.6-0.8,2c-0.1,0.1-0.3,0.1-0.5,0.1
      c0.2,0.5-0.1,1.1-0.3,1.5c1.3,0.5,2.9,0.4,4.3,0.3c0.4,0.1,1,0.2,1.2,0.6c0.3,0.7,0.6,1.2,0.3,2.2c-0.3,0.6-0.8,1-1.5,1
      c0.4-0.2,0.8-0.6,0.9-0.9s0.1-0.8-0.3-1c-0.2,0.1-0.5,0.3-0.7,0.1c-0.4-1-1.2,0.1-1.8-0.4c-0.7-1.3-2,0.3-2.7-1
      c-0.6,0.2-1,0.9-1.6,1.1c-0.7,0.3-1.1-0.5-1.6,0.1c-0.4,0.6-0.9,0.9-1.6,0.7c-0.2-0.1-0.3-0.4-0.5-0.2c-0.4,0.3-0.4,0.6-0.3,1.2
      c0.2,0.6,0.6,1,0.6,1c-0.5-0.3-1-0.9-1.1-1.4c-0.3-1,0.2-1.4,0.6-2.1l0.3-0.3c-0.3-0.2-0.7-0.2-1.1-0.3L65,63
      c-0.8-0.7-2.1-0.9-3-1.4c-1.1,0.3-2.5,0.7-2.9,1.9c-0.3,0.1-0.5,0-0.7-0.1l-2,0.7c-0.1-0.2,0.1-0.4-0.1-0.5c-0.9,0.1-1.8-0.3-2.3-1
      l-0.2-0.4c0.3,0.7,1.1,1,1.8,1.1c1.1,0,1.8-0.2,2.7-1.1c-2-0.6-4.5-0.4-5.9-2.3c-0.2-0.3-0.5-0.6-0.8-0.8l-2.1,0.2l0.2,0.3
      c0.2,0.4,0.2,0.8,0.5,1.2c0.4,0.4,0.7,0.4,1.1,0.7c0.1,0.1,0,0.6,0.1,0.7c3.5,1.8,7.2,4.3,7.1,4.8c0.4-0.1,0.7,0.1,0.9-0.4
      c0.5-1.1-0.1-2.7,1.3-3.4c0.9-0.4,1.8-0.3,2.5,0c0.6,0.3,0.8,0.6,1,1.1c0.1,0.4,0.2,1,0,1.4c-0.1-0.5-0.3-1.4-1.1-1.8
      c-0.1-0.1-0.4,0-0.4,0.1c0,0.3,0,0.7-0.3,0.9s-0.9,0.2-1.3,0c0.4,1.2-1.2,1.8-1.3,3l0.2,0.8c0.8,0.2,1.4,0.3,2.3,0.2
      c0.9-0.1,2-0.9,2.9-0.2c0.7,0.6,1.4,1.2,1.1,2.2c-0.1,0.3-0.1,0.5-0.4,0.6c0.2-0.3,0.2-0.8,0.1-1.1c-0.1-0.4-0.5-0.6-0.8-0.6
      c0,0.2-0.3,0.4-0.5,0.5c-0.7,0.3-1.1-0.6-1.7-0.7c0,0.4-0.5,0.6-0.8,0.7c-0.9,0.2-1.6-1.3-2.4-0.3c0,0.4,0.4,0.8,0.6,1.1
      c0.7,0.8,1.9,1.1,2.1,2.2c-0.1,1,0.3,1.9-0.4,2.6c-0.4,0.5-1.5,0.8-2.2,0.1c0.8,0.4,1.6,0.1,1.8-0.5c0.1-0.2,0.1-0.6-0.1-0.8
      c-0.6,0-1.1-0.3-1.4-0.8c-0.2-0.5,0-1.3-0.8-1.3c-0.6-0.5-1-1.2-0.8-2c-1.7-2.3-3.7,1-5.4-1.1c-0.5,0.2-1.1,0.4-1.6,0.2
      c-0.7-0.2,0.1-1.4-0.8-0.8c-0.7,0.4-0.7,1-0.6,1.8c-0.4-0.6-0.6-1.7,0-2.2c1.9-1.6,4.4,0.8,6.4,0.5c-0.2-0.3-1-0.6-0.5-1.1
      c-0.1-0.5-0.6-0.6-0.9-0.8c-1.8-1-3.8-1.4-5.8-1.8c-0.2,0.2-0.4,0.5-0.7,0.7c-0.1-0.1,0-0.3-0.2-0.4c-0.2,0.4-0.2,1-0.2,1.3
      c-0.4,0.1-0.6-0.3-1-0.4c-0.9,0.9-1.8,1.7-2.9,2c0.2-0.4,0.5-0.7,0.5-1.3c-1.5,0-3.1,0.1-3.9-1.2c-0.3-0.4-0.4-0.9-0.2-1.4
      c0.2-0.2,0.3-0.5,0.5-0.7c-0.4,0.4-0.7,1-0.4,1.5c0.6,1.1,1.8,1,3,0.9c1-0.2,2.6-0.7,2.9-2c-2.7-0.7-4.9-0.9-6.8-3.6
      c-1.6,0.2-1.6,2.3-3.1,2.9c-0.7,0.3-1.6,0.3-2.2-0.2l-0.2-0.2c0.4,0.3,0.9,0.5,1.3,0.2c0.6-0.4,1-0.8,1.1-1.5
      c-0.8-0.2-1.5-1-2.1-1.6l-1.4,0.2c-1.3,1.1-2.8,2.3-4.5,1.7c0.3-0.1,1.1-0.1,1.4-0.5c-0.3-0.1-0.8,0.1-1.1-0.1
      c0.6-0.2,1.2-0.4,1.7-0.9c-0.3-0.5-1-0.1-1.4-0.4c-0.9-0.3-2-0.3-2.5-1.2c0.1,0,0.3,0,0.3-0.1c-1.8-0.7-4.8-0.9-5.1-3.2
      c-0.2-1,0.5-1.6,1.5-2c-0.4,0.3-1.5,0.9-1,1.6c1,1.5,3.5,2,4.2,2s0.5-0.1,0.5-0.1c-0.1-0.2-0.3-0.5-0.4-0.6
      c1.2,0.7,2.7,0.2,3.9,0.3l-0.5,0c3.7,0.4,6-2.4,10-2.8c-0.5-0.1-1-0.3-1.5-1c-0.9,0.5-2.4,1.1-3.2,0c-0.3-0.4-0.3-1-0.1-1.4
      l0.2-0.3l-0.3-0.2c-1.2,0.6-2.4,1.5-3.5,0.5c-0.2-0.2-0.3-0.8-0.2-1.1c0.1-0.3,0.4-0.6,0.7-0.9c0-0.1-0.1-0.2-0.3-0.2
      c-1.1,0.2-3,1.2-4-0.5c-0.5-0.9,0.6-1.8,1.3-2.3c-0.4-0.5-0.9-0.2-1.8,0c-1.1,0.3-2,0.5-2.5-0.1c-0.3-0.3-0.4-0.7-0.2-1.2
      c0.2-0.7,1.6-0.9,2.5-1.2c0.1-0.2-0.1-0.5-0.5-0.5c-1.8,0-4.1,0.9-4.9-1c-0.2-0.4,0.2-0.8,0.5-1c0.5-0.2,0.9-0.2,1.5-0.3
      c0.1-0.2-0.2-0.4-0.8-0.5c-1.5-0.2-3.3-0.1-3.6-1.7c-0.1-0.4,0.1-0.8,0.5-1.2c-0.9-0.4-1.7-0.9-1.9-1.9c-0.1-0.5,0-0.7,0.2-1
      c-0.9-0.7-1.4-1.6-1.1-2.4c-2.2-1-4.7-2.6-4.8-5.5c-1.6-1.3-3.3-3-3-5.2c-0.5-1.2-2.3-2-1.8-3.9c0.1-0.4,0.2-0.5,0.4-0.7
      c-1.8-1.1-3-2.9-2.6-5.2c0.2-0.1,0.4,0.2,0.4,0C6,11.4,5.8,9.9,6.4,9c0.2-0.3,0.5-0.5,0.9-0.6C7.1,8.7,7,9,7.2,9.6z"/>
  </g>
  </svg>
</template>
